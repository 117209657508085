import * as React from 'react';
import { GuideEditNavBar } from '@dozuki/web-js/components';
import { initializeChakraComponent } from 'Shared/chakra-initialize';
import { _js } from '@ifixit/localize';
import { LinkWithoutUnderline } from './Shared/link_component';

function GuideEditNavBarComponent(props) {
   return <GuideEditNavBar _js={_js} mb={6} {...props} LinkComponent={LinkWithoutUnderline} />;
}

initializeChakraComponent('GuideEditNavBarComponent', GuideEditNavBarComponent);
